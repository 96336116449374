/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({
  description,
  lang,
  meta,
  title,
  article,
  shareImage,
  url,
  date,
}) {
  const { strapiGlobal } = useStaticQuery(query)
  const { defaultSeo } = strapiGlobal

  const metaDescription = description || defaultSeo?.metaDescription
  const defaultTitle = defaultSeo?.metaTitle
  const imageUrl = shareImage || defaultSeo?.shareImage.url

  const tags = []

  const language = lang || "es"

  const locale = lang === "es" ? "es_ES" : "en_US"

  if (imageUrl) {
    tags.push(
      {
        name: "image",
        content: imageUrl,
      },
      {
        property: "og:image",
        content: imageUrl,
      },
      {
        name: "twitter:image",
        content: imageUrl,
      }
    )
  }

  const contentType = article ? "article" : "website"

  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      title={title || defaultTitle}
      titleTemplate={title ? title : defaultTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title || defaultTitle,
        },
        {
          property: `og:url`,
          content: `https://symlab.io${url}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: contentType,
        },
        {
          property: `og:locale`,
          content: locale,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: defaultSeo?.twitterUserName || `symmetrics`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        ...tags,
      ].concat(meta)}
    >
      {!article && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "SymLab",
            legalName: "Symmetrics Lab Tecnologia SpA",
            url: "https://symlab.io",
            logo: "https://symmetrics.s3.amazonaws.com/logo-symmetrics.png",
            foundingDate: "2019",
            email: "info@symlab.io",
            sameAs: [
              "https://twitter.com/symmetrics",
              "https://www.linkedin.com/company/symmetricslab/",
            ],
            founders: [
              {
                "@type": "Person",
                name: "Olivers De Abreu",
              },
              {
                "@type": "Person",
                name: "Fabian Paredes",
              },
            ],
            contactPoint: {
              "@type": "ContactPoint",
              contactType: "Customer Support and Sales",
              email: "info@symlab.io",
            },
          })}
        </script>
      )}

      {article && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            headline: title,
            image: imageUrl,
            publisher: "SymLab",
            url: `https://symlab.io${url}`,
            datePublished: date || new Date().toISOString(),
            description,
          })}
        </script>
      )}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `es`,
  meta: [],
  description: ``,
  article: false,
  url: "",
  date: new Date().toISOString(),
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  article: PropTypes.bool,
  url: PropTypes.string,
  date: PropTypes.string,
}

const query = graphql`
  query {
    strapiGlobal {
      defaultSeo {
        metaDescription
        metaTitle
        twitterCardType
        twitterUserName
        type
        shareImage {
          url
        }
      }
    }
  }
`

export default Seo
