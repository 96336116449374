import React from "react"

import { goToID } from "../../utils/helpers"

const ToTop = () => {
  return (
    <div className="fixed bottom-4 right-4">
      <button
        type="button"
        onClick={e => goToID(e, "#home")}
        className="text-center bg-white p-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 mx-auto"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 11l7-7 7 7M5 19l7-7 7 7"
          />
        </svg>
        <span>Subir</span>
      </button>
    </div>
  )
}

export default ToTop
